@import "../../../styles/varaibles.scss";
@import "../../../styles/mixins.scss";

$height: 54px;

.button {
  @include text($colorWhite, 20px, 500, null, null);
  @include flex(row, center, center, nowrap);
  width: 100%;
  max-width: 240px;
  padding: 10px 20px;
  height: $height;
  background-color: $primaryColor;
  border-style: none;
  cursor: pointer;

  box-shadow:
    0px 0px 20px rgba(50, 33, 13, 0.35),
    inset 0px 0px 15px rgba(63, 40, 14, 0.25);

  &:hover {
    background: #c1b397;
    border: 1px solid #583711;
    box-shadow:
      0px 0px 20px rgba(50, 33, 13, 0.35),
      inset 0px 0px 15px rgba(63, 40, 14, 0.25);
    color: $primaryColor;
  }
  @media (max-width: 768px) {
    height: 40px;
    font-size: 16px;
  }
  @media (max-width: 360px) {
    max-width: 100%;
    height: 40px;
    font-size: 14px;
  }
}
:global {
  .small {
    width: 120px;
    height: 30px;
    font-size: 15px;
  }
}
