@import "../../styles/varaibles.scss";
@import "../../styles/mixins.scss";

.modal_container {
  margin: auto;
  position: fixed;
  bottom: 120px;
  right: 25px;
  z-index: 100;
  @media (max-width: 768px) {
    bottom: 80px;
    right: 18px;
  }
  @media (max-width: 360px) {
    bottom: 80px;
    right: 15px;
  }
}

.wrapper {
  padding: 5px;
  @include flex(row, space-between, center, nowrap);
  //@include text(rgba(66, 66, 66, 1), 20px, 500, 28px, null);
  width: 500px;
  height: 100px;
  border: 2px solid;
  border-radius: 4px;
  letter-spacing: 0em;
  text-align: left;
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
  @media (max-width: 768px) {
    width: 482px;
    height: 80px;
  }
  @media (max-width: 520px) {
    width: 100%;
    height: 100px;
  }
  @media (max-width: 370px) {
    height: 120px;
  }

  .content {
    font-size: 20px;
    line-height: normal;
    @media (max-width: 768px) {
      font-size: 16px;
    }
    @media (max-width: 360px) {
      font-size: 14px;
    }
  }

  &.success {
    background-color: #EEFBF6;
    border-color: #C0F8E3;
  }

  &.danger {
    background-color: rgba(251, 239, 238, 1);
    border-color: rgba(248, 193, 192, 1);
  }
}

