@import "../../styles/varaibles.scss";
@import "../../styles/mixins.scss";

.contacts-info {
  display: grid;
  grid-template-areas:
    "logo header"
    "logo  main"
    "logo footer";
  width: 100%;
  grid-template-columns: minmax(87px, auto) 1fr;

  .footer-logo {
    grid-area: logo;
    @include adaptive-value(width, 187, 70);
    margin-right: 72px;
    @media (max-width: 768px) {
      margin-right: 31px;
    }
    @media (max-width: 360px) {
      margin-right: 17px;
    }

    & svg {
      width: 100%;
      height: auto;
    }
  }

  .contacts-header {
    grid-area: header;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }

    .title {
      margin-bottom: 10px;
      @include text($text-color, 35px, 500, 43px, uppercase);
      @media (max-width: 768px) {
        line-height: normal;
        font-size: 18px;
        margin-bottom: 0px;
      }
    }

    .subtitle {
      @include text($text-color, 35px, null, 43px, uppercase);
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: normal;
      }
    }
  }

  .contacts-description {
    width: 100%;
    grid-area: main;
    @include flex(row, space-between, null, wrap);
    margin-bottom: 15px;
    @media (max-width: 360px) {
      flex-direction: column;
      row-gap: 10px;
    }

    .text {
      max-width: 481px;
      margin-bottom: 15px;
      @media (max-width: 768px) {
        max-width: 311px;
        margin-right: 35px;
      }
      @media (max-width: 360px) {
        margin: 0px;
      }

      & p {
        //@media (max-width: 768px) {
        margin-bottom: 10px;
        //}
      }
    }

    .phones {
      @include flex(column, null, flex-start, nowrap);
      margin-right: 10px;
      margin-bottom: 15px;
      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
    }

    .socials {
      @include flex(row, null, flex-start, nowrap);
      gap: 16px;
    }

    & p {
      @include text($text-color, 20px, 400, normal, none);
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
  .payments {
    grid-area: footer;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(6, 1fr);
    max-width: 481px;
    @media (max-width: 768px) {
      max-width: 311px;
      column-gap: 10px;
    }

    & svg,
    div {
      width: 100%;
      height: 100%;
      max-width: 74px;
      max-height: 85px;
      @include flex(row, flex-start, center, nowrap);
    }
  }
}
