@import "../../styles/varaibles.scss";
@import "../../styles/mixins.scss";

.face-block {
  width: 100%;
  height: 356px;
  position: relative;
  @media (max-width: 768px) {
    height: 311px;
  }
  @media (max-width: 360px) {
    height: 180px;
  }

  .title {
    padding-top: 130px;
    @include text($colorWhite, 70px, 600, normal, normal);
    @media (max-width: 768px) {
      padding-top: 115px;
      margin-left: 59px;
      @include text($colorWhite, 50px, 700, 50px, normal);
    }
    @media (max-width: 500px) {
      margin-left: 35px;
      font-size: 35px;
    }
    @media (max-width: 360px) {
      padding-top: 60px;
      margin-left: 24px;
      @include text($colorWhite, 28px, 700, 28px, normal);
    }
  }
}
