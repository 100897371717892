.card {
  position: relative;
  height: calc(231px + (277 - 231) * ((100vw - 768px) / (1440 - 768)));
  box-shadow: 0px 4px 17px 5px rgba(218, 203, 203, 0.4);
  @media (max-width: 768px) {
    box-shadow: none;
    aspect-ratio: unset;
    height: auto;
  }

  .contain {
    object-fit: contain;
  }

  .cover {
    object-fit: cover;
  }

  img {
    height: 100%;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .flag {
    position: absolute;
    bottom: 10px;
    left: -15px;
    width: 80%;
    @media (max-width: 768px) {
      position: static;
      height: 30px;
      width: 95%;
    }
    @media (max-width: 450px) {
      width: 97.5%;
    }

    &::before {
      @media (max-width: 768px) {
        right: 2px;
      }
      @media (max-width: 670px) {
        right: 0px;
      }
      @media (max-width: 505px) {
        right: -4px;
      }
    }

    &::after {
      @media (max-width: 768px) {
        right: 2px;
      }
      @media (max-width: 670px) {
        right: 0px;
      }
      @media (max-width: 505px) {
        right: -4px;
      }
    }
    & p {
      font-size: 16px;
    }
  }
}
