@import "../../styles/varaibles.scss";
@import "../../styles/extensions.scss";

.container {
  width: 100%;
  position: relative;

  & :global(.slick-slider) {
    & :global(.slick-list) {
      margin: 0 -20px;
      @media (max-width: 1250px) {
        margin: 0 -18px;
      }

      & :global(.slick-track) {
        & :global(.slick-slide) {
          padding: 0 12px;
          @media (max-width: 550px) {
            display: flex;
            flex-direction: column;
            gap: 25px;
          }

          & > div {
            margin: 5px;
            display: flex;
            flex-direction: column;
            gap: 35px;
            @media (max-width: 768px) {
              gap: 25px;
            }
          }
        }
      }
    }
    @extend %customPaging;
  }
}
.preload {
  text-align: center;
}
