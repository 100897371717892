@import "../../../styles/varaibles.scss";
.container {
  position: fixed;
  z-index: 10;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  padding: 100px 0px;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  overflow: auto;
}
.form {
  //width: 760px;
  width: 53%;
  position: relative;
  height: fit-content;
  background: $secondaryColor;
  padding: 40px 50px;
  box-shadow:
    0px 0px 10px 1px rgba(126, 82, 14, 0.4),
    inset 0px 0px 20px 15px rgba(158, 149, 129, 0.5);
  @media (max-width: 870px) {
    padding: 20px 15px;
  }
  @media (max-width: 768px) {
    width: 96%;
  }
  @media (max-width: 360px) {
    width: 100%;
  }

  .close-button {
    position: absolute;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 12px;
    right: 12px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
  h1 {
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    text-transform: uppercase;
  }
}
