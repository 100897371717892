.info {
    flex: 1; 
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; 
    padding-left: 20px;
    padding-right: 20px;

    .content {
        font-size: 30px;
        line-height: 40px;

        @media (max-width: 768px) {
            font-size: 20px;
            line-height: 30px;
  }
    }
}
.loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}