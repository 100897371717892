@import "../../../styles/mixins.scss";
@import "../../../styles/varaibles.scss";

.card {
  display: flex;
  background: $colorWhite;
  box-shadow: 0px 4px 17px 5px rgba(218, 203, 203, 0.37);
  @media (max-width: 550px) {
    max-height: 98px;
  }

  &__image-container {
    position: relative;
    width: 100%;
    min-height: 169px;
    max-width: 169px;
    @media (max-width: 768px) {
      max-width: 98px;
      min-height: 98px;
    }
    @media (max-width: 450px) {
      height: 100%;
      min-width: 98px;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      aspect-ratio: 1 / 1;
    }
  }
  &__info {
    @include flex(column, null, null, null);
    align-self: center;
    padding: 5px 5px 5px 35px;
    min-width: 0;
    @media (max-width: 768px) {
      padding-left: 15px;
    }
    @media (max-width: 360px) {
      padding-top: 2px;
    }
    &-wrapper {
      max-width: 301px;
      @media (max-width: 550px) {
        max-width: 100%;
      }
    }
  }
  &__title {
    margin-bottom: 25px;
    @include ellipsis(2);
    @include text($text-color, 20px, 600, normal, uppercase);
    @media (max-width: 950px) {
      margin-bottom: 10px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
      margin-bottom: 2px;
    }
    @media (max-width: 440px) {
      -webkit-line-clamp: 1;
    }
  }
  &__description {
    font-size: 18px;
    line-height: normal;
    @include ellipsis(3);
    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 14px;
    }
  }
}
