@import "../../styles/varaibles.scss";
@import "../../styles/mixins.scss";
@import "../../styles/extensions.scss";

.tabs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 37px;
  grid-row-gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 360px) {
    grid-template-columns: 1fr;
  }

  .tab-item {
    @include flex(row, center, center, nowrap);
    @include text($text-color, 20px, 500, 24px, none);
    padding: 6px 6px;
    width: 100%;
    background: $secondaryColor;
    box-shadow: 0px 0px 5px rgba(193, 179, 151, 0.35),
    inset 0px 0px 10px rgba(63, 40, 14, 0.25);
    text-align: center;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &.active {
      background: $text-color;
      box-shadow: 0px 0px 5px rgba(193, 179, 151, 0.35),
      inset 0px 0px 10px rgba(63, 40, 14, 0.25);
      color: $colorWhite;
    }
  }
}

.images-grid {
  margin-top: 30px;
  width: 100%;
  height: auto;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 768px) {
    margin-top: 25px;
    grid-row-gap: 14px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 420px) {
    margin-top: 25px;
    grid-template-columns: 1fr;
  }

  .imageBlock {
    height: calc(183px + (238 - 183) * ((100vw - 768px) / (1440 - 768)));
    box-shadow: 0px 4px 10px 5px rgba(218, 203, 203, 0.4);
    cursor: pointer;

    @media (max-width: 420px) {
      height: 183px;
    }

    .image {
      width: 100%;
      height: 100%;
    }

    .contain {
      object-fit: contain;
    }

    .cover {
      object-fit: cover;
    }
  }

  & :global(.slick-slider) {
    width: 91vw;

    & :global(.slick-list) {
      overflow: hidden;
      overflow-x: clip;

      & :global(.slick-track) {
        display: flex;

        & :global(.slick-slide) {
          padding: 0 6px;
          display: flex;
          flex-direction: column;
          gap: 14px;
          @media (max-width: 360px) {
            padding: 0 5px;
          }
        }
      }
    }
  }

  @extend %dots;
}

.pages {
  margin: 50px 0;
  @include flex(row, center, center, no-wrap);

  @media (max-width: 768px) {
    margin: 25px 0;
  }
  @media (max-width: 420px) {
    display: none;
  }

  .page, .currentPage {
    width: 50px;
    height: 50px;
    @include flex(row, center, center, no-wrap);
    @include text(null, 35px, 500, normal, uppercase);
    border: 3px solid $text-color;
    color: $colorWhite;
    background-color: $text-color;
    border-radius: 100%;
    margin: 0 10px;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 25px;
      height: 25px;
      font-size: 14px;
    }
  }

  .currentPage {
    color: $text-color;
    background-color: $colorWhite;
  }

}

.image-viewer {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;

  .close {
    color: $colorWhite;
    position: absolute;
    right: 0px;
    top: -30px;
    font-size: 30px;
    cursor: pointer;
    @media (max-width: 768px) {
      font-size: 20px;
      top: -20px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .photo-view {
    width: 80%;
    height: 80%;
    position: relative;
    @media (max-width: 550px) {
      width: 85%;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .arrow-next,
  .arrow-prev {
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
    }
  }

  .arrow-next {
    right: 0px;
  }

  .arrow-prev {
    left: 0px;
  }
}

.preload {
  text-align: center;
  margin-top: 50px;
}

.cap {
  @include text($text-color, 20px, 500, 24px, none);
  @include flex(row, center, center, nowrap);
  padding: 30px;
}