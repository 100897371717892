@import "~react-image-gallery/styles/scss/image-gallery.scss";

.coverOrigin {
  img {
    object-fit: cover !important;
  }
}

.containOrigin {
  img {
    object-fit: contain !important;
  }
}

.coverThumbnail {

  span > img {
    object-fit: cover !important;
    aspect-ratio: 16 / 9;
  }
}

.containThumbnail {
  span > img {
    object-fit: contain !important;
    aspect-ratio: 16 / 9;
  }
}

