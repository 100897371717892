@import "../../../styles/varaibles.scss";

.from-date {
  position: relative;
}

.calendar {
  position: absolute;
  z-index: 100;
  left: 0px;
  top: 64px;
}
