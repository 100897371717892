@import "../../styles/varaibles.scss";
@import "../../styles/mixins.scss";
@import "../../styles/extensions.scss";

.card {
  width: 100%;
  padding: 30px 66px 35px 40px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 17px 5px rgba(218, 203, 203, 0.4);
  @media (max-width: 768px) {
    max-width: 662px;
    padding: 10px 15px 20px;
    border: 1px solid rgba(88, 55, 17, 0.06);
    box-shadow: 0px 0px 10px 1px rgba(218, 203, 203, 0.68);
  }

  .title {
    @include text($text-color, 35px, 600, normal, uppercase);
    margin-bottom: 15px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .sliderContainer {
    height: 488px;
    @media (max-width: 1100px) {
      height: 372px;
    }
    @media (max-width: 768px) {
      height: 280px;
    }
    @media (max-width: 550px) {
      height: 200px;
    }
    @media (max-width: 360px) {
      height: 157px;
    }

    & :global(.image-gallery-content) {
      display: grid;
      grid-template-columns: 75% 23%;
      gap: 2%;
      @media (max-width: 360px) {
        grid-template-columns: 100%;
      }
    }

    & :global(.image-gallery-slide) {
      height: 488px;
      width: 101%;
      @media (max-width: 1100px) {
        height: 372px;
      }
      @media (max-width: 768px) {
        height: 280px;
      }
      @media (max-width: 550px) {
        height: 200px;
      }
      @media (max-width: 360px) {
        height: 157px;
      }

      img {
        height: 100%;
      }
    }

    & :global(.image-gallery-slide-wrapper) {
      box-shadow: 0px 4px 17px 5px rgba(218, 203, 203, 0.4);
      width: 100%;
    }
    @extend %iconGallery;

    & :global(.image-gallery-thumbnails-wrapper.right) {
      width: unset;
    }

    & :global(.image-gallery-thumbnail) {
      width: 100%;
      height: 157px;
      overflow: hidden;
      box-shadow: 0px 4px 17px 5px rgba(218, 203, 203, 0.4);
      @media (max-width: 1100px) {
        height: 120px;
      }
      @media (max-width: 768px) {
        height: 90px;
      }
      @media (max-width: 550px) {
        height: 64px;
      }

      span {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

    }

    & :global(.image-gallery-thumbnails-container) {
      margin-top: 0px;
      @media (max-width: 360px) {
        display: none;
      }
    }

    & :global(.image-gallery-thumbnail.active) {
      border: 3px solid $primaryColor;
    }

    & :global(.image-gallery-thumbnail:hover) {
      border: 3px solid $primaryColor;
    }

    & :global(.image-gallery-thumbnail + .image-gallery-thumbnail) {
      margin-left: 13px;
    }

    & :global(.image-gallery-thumbnails .image-gallery-thumbnails-container) {
      text-align: left;
    }

    & :global(.image-gallery-icon) {
      z-index: 1;
    }
    @extend %fullscreen;
  }

  .info {
    max-width: 75%;
    margin-top: 25px;
    @media (max-width: 768px) {
      margin-top: 15px;
    }
    @media (max-width: 550px) {
      max-width: 100%;
      margin-top: 20px;
    }

    & p {
      text-align: justify;
      @include text($text-color, 18px, 400, normal, none);
      margin-bottom: 25px;
      @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 10px;
      }
      @media (max-width: 360px) {
        margin-bottom: 15px;
      }
    }

    .price {
      @include text($text-color, 30px, 500, normal, uppercase);
      margin-top: 10px;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 20px;
        margin-bottom: 7px;
      }
      @media (max-width: 360px) {
        margin-bottom: 10px;
      }
    }

    .line {
      border: none;
      width: 100%;
      background: $text-color;
      height: 2px;
      margin-bottom: 15px;
      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 10px;

      & div {
        @include text($text-color, 18px, 400, normal, none);
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
      & .numberPrice {
        @media (max-width: 550px) {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
.preload {
  text-align: center;
}
