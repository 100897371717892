@import "../../styles/varaibles.scss";
@import "../../styles/mixins.scss";

.header {
  height: 84px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2;
  transition-duration: 0.25s;
  padding-top: 10px;
  background-color: $text-color;
  @media (max-width: 360px) {
    padding-top: 2px;
  }
  @media (max-width: 768px) {
    height: 80px;
    background-color: $text-color;
  }
  @media (max-width: 360px) {
    height: 45px;
  }
  .content-container {
    width: 100%;
    height: 100%;
    position: relative;
    @include flex(row, space-between, center, nowrap);
    @media (max-width: 360px) {
      position: static;
    }
    .navigation {
      width: 73%;
      @media (max-width: 1200px) {
        display: none;
      }
    }
  }
}

.menu {
  position: absolute;
  height: 0px;
  opacity: 0;
  overflow: hidden;
  top: 74px;
  left: 0px;
  width: 100%;
  background-color: rgba(63, 38, 10, 0.9);
  transition-duration: 0.25s;
  @media (max-width: 768px) {
    top: 70px;
  }
  @media (max-width: 360px) {
    top: 45px;
  }

  &.menu-open {
    height: fit-content;
    opacity: 1;
  }
  nav {
    padding: 10px 0px;
    gap: 10px;
    @include flex(column, null, flex-start, null);
    @media (max-width: 360px) {
      gap: 5px;
    }
    a {
      border-bottom: 1px solid $secondaryColor;
      width: 100%;
      height: auto;
      @include flex(column, center, null, null);
      &::after {
        display: none;
      }

      &:hover {
        font-weight: 600;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
