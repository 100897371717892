@import "../../styles/varaibles.scss";

.houses-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
  @media (max-width: 1120px) {
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  .preload {
    font-weight: 700;
    text-align: center;
  }
}
