@import "../../../../styles/mixins.scss";

.container {
  max-width: 60px;
  max-height: 60px;
  @include flex(row, center, center, nowrap);
  cursor: pointer;
  @media (max-width: 768px) {
    max-width: 39px;
    max-height: 39px;
  }

  &:hover {
    opacity: 0.7;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
