@import "../../styles/varaibles.scss";
@import "../../styles/extensions.scss";
@import "../../styles/mixins.scss";

$height: 30px;
$triangles-height: calc($height / 2);

.container {
  display: grid;
  grid-template-columns: minmax(331px, 822px) 1fr;
  grid-template-rows: 85px 1fr;
  row-gap: 26px;
  column-gap: 40px;
  position: relative;
  @media (max-width: 768px) {
    grid-template-columns: minmax(100px, 353px) minmax(100px, 353px);
    grid-template-rows: 60px 1fr;
    column-gap: 26px;
    row-gap: 20px;
  }
  @media (max-width: 550px) {
    display: flex;
    flex-direction: column;
  }

  .house-info {
    width: 100%;
    grid-column: 1 / 3;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 17px 5px rgba(218, 203, 203, 0.4);
    padding: 0px 30px;
    @media (max-width: 550px) {
      width: 97%;
      position: absolute;
      top: 325px;
      background-color: $secondaryColor;
      padding: 2px 0px 2px 10px;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 0;
        right: -14px;
        border-left: $triangles-height solid $secondaryColor;
        border-top: $triangles-height solid transparent;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 0;
        right: -14px;
        border-left: $triangles-height transparent;
        border-right: $triangles-height solid transparent;
        border-top: $triangles-height solid $secondaryColor;
      }
      @media (max-width: 360px) {
        top: 219px;
      }
    }

    & h1 {
      @extend %h1Title;
      @media (max-width: 550px) {
        display: none;
      }
    }

    .block-commonInfo {
      display: flex;
      gap: 30px;
      @media (max-width: 768px) {
        gap: 11px;
      }

      div {
        display: flex;
        align-items: center;

        & span {
          @extend %persNum;
        }
      }

      .beds-container {
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        @media (max-width: 550px) {
          flex-direction: row;
          align-items: center;
        }

        & p {
          @include text($text-color, 20px, 400, normal, uppercase);
          @media (max-width: 768px) {
            font-size: 16px;
          }
          @media (max-width: 550px) {
            text-transform: none;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .left-column {
    & :global(.image-gallery-slides) {
      box-shadow: 0px 0px 17px 5px rgba(218, 203, 203, 0.4);
      & :global(.image-gallery-slide) {
        cursor: pointer;
        height: 488px;
        @media (max-width: 1100px) {
          height: 340px;
        }
        @media (max-width: 768px) {
          height: 194px;
        }
        @media (max-width: 550px) {
          height: 300px;
        }
        @media (max-width: 360px) {
          height: 194px;
        }
        img {
          max-width: 101%;
          height: 100%;
        }
      }
    }
    @extend %iconGallery;

    & :global(.image-gallery-thumbnails-container) {
      margin-top: 20px;
      @media (max-width: 768px) {
        margin-top: 10px;
      }
      @media (max-width: 550px) {
        display: none;
      }

      & :global(.image-gallery-thumbnail) {
        width: 252px;
        height: 157px;
        overflow: hidden;
        @media (max-width: 1100px) {
          width: 177px;
          height: 110px;
        }
        @media (max-width: 768px) {
          width: 103px;
          height: 63px;
        }

        span {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    & :global(.image-gallery-thumbnail.active) {
      border: 3px solid $primaryColor;
    }

    & :global(.image-gallery-thumbnail:hover) {
      cursor: pointer;
      border: 3px solid $primaryColor;
    }

    & :global(.image-gallery-thumbnail + .image-gallery-thumbnail) {
      margin-left: 13px;
      box-shadow: 0px 4px 17px 5px rgba(218, 203, 203, 0.4);
      @media (max-width: 768px) {
        margin-left: 5px;
      }
    }

    & :global(.image-gallery-thumbnails .image-gallery-thumbnails-container) {
      text-align: left;
    }

    & :global(.image-gallery-icon) {
      z-index: 1;
    }

    @extend %fullscreen;

    // & :global(.image-gallery-icon.image-gallery-fullscreen-button) { 
    //     display: none !important; 
    // }
  }

  .description-short,
  .description-long {
    @include text($text-color, 18px, 400, normal, none);
    text-align: justify;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  .description-short {
    margin-top: 35px;
    margin-bottom: 25px;
    @media (max-width: 768px) {
      margin-bottom: 15px;
      margin-top: 25px;
    }
    @media (max-width: 550px) {
      margin-top: 70px;
    }
  }
}

.right-column {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  @media (max-width: 550px) {
    display: grid;
    grid-template-rows: repeat(4, auto);
  }

  & .form {
    @include flex(column, center, center, nowrap);
    height: auto;
    min-width: 0px;
    @media (max-width: 768px) {
      margin: 0px;
      align-items: flex-start;
    }
    @media (max-width: 550px) {
      grid-row: 4;
    }

    button {
      width: 100%;
      max-width: 297px;
      margin-left: 0px;
      margin-top: 30px;
      @media (max-width: 550px) {
        max-width: 513px;
        margin-top: 25px;
      }
    }
  }

  .features {
    @media (max-width: 550px) {
      grid-row: 2/3;
    }

    & h2 {
      @extend %h2Title;
    }

    .line {
      width: 100%;
      height: 2px;
      background-color: $text-color;
      margin: 5px 0px 10px;
    }

    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 14px;
      grid-column-gap: 10px;
      @media (max-width: 715px) {
        grid-template-columns: 1fr;
      }

      .grid-item {
        @include flex(row, flex-start, flex-start, nowrap);
        font-size: smaller;
        gap: 20px;
      }
    }
  }

  .prices {
    max-height: 179px;
    width: 100%;
    background: $colorWhite;
    border: 1px solid rgba(88, 55, 17, 0.06);
    box-shadow: 0px 0px 17px 5px rgba(218, 203, 203, 0.4);
    position: relative;
    padding: 65px 18px 23px 40px;
    @include flex(column, flex-end, null, nowrap);
    @media (max-width: 768px) {
      padding: 44px 71px 17px 71px;
    }
    @media (max-width: 713px) {
      padding-left: 25px;
      padding-right: 25px;
    }
    @media (max-width: 550px) {
      grid-row: 1/2;
    }

    .row {
      @include text($text-color, 18px, 400, normal, none);
      @media (max-width: 768px) {
        font-size: 16px;
      }

      span {
        @include text($text-color, 30px, 600, normal, none);
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
    }

    .row + .row {
      margin-top: 20px;
      @media (max-width: 768px) {
        margin-top: 0px;
      }
    }

    .flag {
      position: absolute;
      top: 15px;
      left: -10px;
      width: 80%;
      //max-width: 279px;
      padding-left: 15px;
      @media (max-width: 768px) {
        top: 5px;
      }
    }
  }
}

.preload {
  margin: 0 auto;
  text-align: center;
}

:global(:not(:root):fullscreen::backdrop) {
  background: rgba(0, 0, 0, 0.4);
}
