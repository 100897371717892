.logo {
  max-width: 172px;
  height: 70px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 149px;
    height: 60px;
  }
  @media (max-width: 360px) {
    width: 94px;
    height: 43px;
  }

  &:hover {
    opacity: 0.7;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}
