@import "../../../styles/varaibles.scss";

.first-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-column-gap: 30px;
  margin-top: 25px;
  @media (max-width: 1300px) and (min-width: 768px) {
    grid-template-row: 1fr 1fr;
    grid-template-columns: 1fr;
  }
  @media (max-width: 690px) {
    grid-template-row: 1fr 1fr;
    grid-template-columns: 1fr;
  }

  .label {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }
}

.second-grid {
  grid-template-columns: 0.95fr 1.05fr;
  grid-column-gap: 30px;
  margin-top: 15px;
  @media (max-width: 1300px) and (min-width: 768px) {
    grid-template-row: 1fr 1fr;
    grid-template-columns: 1fr;
  }
  @media (max-width: 690px) {
    grid-template-row: 1fr 1fr;
    grid-template-columns: 1fr;
  }
}

h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  margin: 25px 0px 15px;
}

.grid {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 15px;
  @media (max-width: 768px) {
    grid-column-gap: 42px;
  }

  &.contacts {
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 1300px) and (min-width: 768px) {
      grid-template-row: 1fr 1fr;
      grid-template-columns: 1fr;
    }
    @media (max-width: 690px) {
      grid-template-row: 1fr 1fr;
      grid-template-columns: 1fr;
    }
  }
}

.more {
  grid-template-columns: 1fr 1fr 1fr;
  align-content: space-between;
  @media (max-width: 1300px) and (min-width: 768px) {
    grid-template-row: 1fr 1fr;
    grid-template-columns: 1fr;
  }
  @media (max-width: 690px) {
    grid-template-row: 1fr 1fr;
    grid-template-columns: 1fr;
  }
}

.from-date {
  position: relative;
}

.calendar {
  position: absolute;
  z-index: 100;
  left: 0px;
  top: 64px;
}

:global(.rdp) {
  padding: 20px;
  background: $secondaryColor;
  border: 1px solid $primaryColor;
  margin: 0em !important;
}

:global(.rdp-day_selected) {
  background: $primaryColor !important;
}

.textarea-container {
  margin-top: 15px;

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.textarea {
  padding: 10px;
  width: 100%;
  resize: none;
  overflow-y: hidden;
  background: #e2d6be;
  border: 1px solid $primaryColor;
}

.submit-button {
  margin-top: 15px;
}

.customCheckbox {
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }

  & + label::before {
    content: "";
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $primaryColor;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    cursor: pointer;
  }

  &:checked + label::before {
    border-color: $primaryColor;
    background-color: rgba(126, 82, 14, 0.4);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }

  &:not(:disabled):not(:checked) + label:hover::before {
    border-color: $primaryColor;
  }

  /* стили для чекбокса, находящегося в фокусе */
  &:focus + label::before {
    box-shadow: 0 0 0 0.1rem rgba(126, 82, 14, 0.4);
    outline: 1px solid $primaryColor;
  }

  /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
  &:focus:not(:checked) + label::before {
    border-color: $primaryColor;
  }

  /* стили для чекбокса, находящегося в состоянии disabled */
  &:disabled + label::before {
    background-color: #e9ecef;
  }
}

.modal-link {
  cursor: pointer;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}

.error + label::before {
  outline: 2px solid $errorRedColor;
}
