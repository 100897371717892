@import "../../styles/varaibles.scss";
.wrapper {
  position: fixed;
  z-index: 20;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  padding: 10px 0px;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  overflow: auto;

  .container {
    width: 85%;
    max-width: 1000px;
    position: relative;
    height: fit-content;
    background: $colorWhite;
    padding: 10px 60px 20px;
    box-shadow:
      0px 0px 10px 1px rgba(126, 82, 14, 0.4),
      inset 0px 0px 20px 15px rgba(158, 149, 129, 0.5);
    @media (max-width: 768px) {
      padding-left: 25px;
      padding-right: 25px;
    }
    @media (max-width: 400px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    .container-icon {
      display: flex;
      justify-content: end;
    }
      h2 {
        text-align: center;
        text-transform: uppercase;
        @media (max-width: 768px) {
          font-size: 18px;
        }
        @media (max-width: 360px) {
          font-size: 14px;
        }
      }
      .content {
        margin-top: 10px;
        margin-bottom: 15px;
        @media (max-width: 360px) {
          font-size: 14px;
        }
      }

    .button {
      display: inline-block;
    }
  }
}
