@import "../../styles/varaibles.scss";
@import "../../styles/mixins.scss";
.rules-block {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 75px;
  grid-row-gap: 47px;
  margin-top: 39px;
  padding-right: 24px;
  padding-left: 33px;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 65px;
    grid-row-gap: 15px;
    margin-top: 10px;
    padding-left: 23px;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.cap {
  @include text($text-color, 20px, 500, 24px, none);
  @include flex(row, center, center, nowrap);
  padding: 10px;
}
.preload {
  text-align: center;
  margin-top: 50px;
}