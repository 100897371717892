.cross {
  width: 26px;
  height: 26px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 22px;
    height: 22px;
  }
  @media (max-width: 360px) {
    width: 20px;
    height: 20px;
  }
}
