.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 1250px) {
    padding-right: 18px;
    padding-left: 18px;
  }
  @media (max-width: 360px) {
    padding-right: 15px;
    padding-left: 15px;
  }
}
