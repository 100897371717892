@import "../../../styles/varaibles.scss";
@import "../../../styles/mixins.scss";

.card {
  position: relative;
  width: 100%;
  padding: 67px 24px 24px 21px;
  background-color: $colorWhite;
  box-shadow: 0px 0px 17px 5px rgba(218, 203, 203, 0.4);
  @media (max-width: 768px) {
    padding: 41px 25px 12px 15px;
  }

  .flag {
    position: absolute;
    top: 17px;
    left: -15px;
    width: 73%;
    @media (max-width: 768px) {
      top: 5px;
      left: -10px;
      height: 22px;
    }
    @media (max-width: 360px) {
      display: none;
    }

    p {
      @include text($text-color, 20px, 500, normal, none);
      @media (max-width: 768px) {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  .row {
    @include flex(row, space-between, center, wrap);
    gap: 10px;
    width: 100%;

    .time {
      @include flex(row, flex-start, center, nowrap);
      gap: 10px;

      div {
        @include flex(row, center, center, nowrap);

        svg {
          width: 33px;
          height: 33px;
          @media (max-width: 768px) {
            width: 21px;
            height: 21px;
          }
        }
      }

      p {
        @include text($text-color, 35px, 500, normal, uppercase);
        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }

    .price {
      @include flex(row, space-between, center, nowrap);
      padding: 1px 22px;
      background-color: $secondaryColor;
      box-shadow: inset 0px 0px 10px 2px rgba(88, 55, 17, 0.25);
      min-width: 148px;
      @media (max-width: 768px) {
        padding: 2px 11px;
        min-width: 70px;
      }

      &,
      span {
        @include text($text-color, 30px, 500, normal, uppercase);
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
}
