.container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 26px;
    height: 26px;
  }

  svg {
    width: 29px;
    height: 31px;
    @media (max-width: 768px) {
      width: 17px;
      height: 18px;
    }
  }
}
