@import "../../../styles/varaibles.scss";
@import "../../../styles/mixins.scss";

.container {
  position: relative;
  width: 100%;
  &:focus {
    outline: none !important;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.control {
  &:hover {
    border: none;
  }
}
.customSelect {
  width: 100%;

  &:focus {
    border-color: 2px solid $primaryColor;
  }

  span {
    visibility: hidden;
  }
  &__wrapper {
    @include flex(row, flex-end, center, nowrap);
    @include text(null, 15px, 500, null, none);
    position: relative;
    transition-duration: 0.25s;
    cursor: pointer;
    border: none;
    outline: none;
    border-bottom: 3px solid $primaryColor;
    padding: 5px 5px 5px 23px;
    width: 100%;
    height: 31px;
    background: none;
    text-overflow: ellipsis;
  }
  &__control {
    @include text(null, 15px, 500, null, none);
    width: inherit;
    position: absolute;
    right: 0%;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    &:focus {
      border: 1px solid $primaryColor !important;
    }
  }

  &__value-container {
    @include text(null, 15px, 500, null, none);
    width: 100%;
    overflow: hidden;
    padding: 0px;
  }
  &__placeholder {
    @include text(null, 15px, 500, null, none);
  }
  &__menu {
    position: absolute;
    background-color: $secondaryColor !important;
    border: 2px solid $primaryColor;
    z-index: 100;
  }

  &__option {
    &:hover,
    &:active,
    &:focus {
      background-color: #e2d6be;
    }
  }

  &__single-value {
    @include flex(row, null, center, nowrap);
    @include text(null, 15px, 500, 18px, none);
    width: fit-content;
    cursor: pointer;
    // padding-top: 10px;

    &:hover {
      color: black;
    }
  }

  &__option:focus {
    background-color: #e2d6be;
  }
}

.icon {
  position: absolute;
  left: 0px;
  bottom: 6px;
}

.error {
  border-bottom: 3px solid $errorRedColor;
}
.label {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
