@import "../../../styles/varaibles.scss";
@import "../../../styles/mixins.scss";

.card {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 12px 18px;
  background-color: $secondaryColor;
  box-shadow:
    0px 0px 10px rgba(50, 33, 13, 0.35),
    inset 0px 0px 15px rgba(0, 0, 0, 0.25);
  @include flex(row, space-between, center, nowrap);
  @media (max-width: 768px) {
    padding: 15px;
  }
  @media (max-width: 350px) {
    padding: 10px 5px;
    gap: 5px;
  }

  .title {
    @include text($text-color, 20px, 500, normal, uppercase);
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .time-price-block {
    @media (max-width: 768px) {
      @include flex(row, flex-start, center, nowrap);
      gap: 20px;
    }
    @media (max-width: 710px) {
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;
    }
    @media (max-width: 550px) {
      flex-direction: row;
      gap: 5px;
    }
    @media (max-width: 310px) {
      flex-direction: column;
      align-items: flex-end;
      gap: 5px;
    }

    .time {
      @include flex(row, flex-start, center, nowrap);

      div {
        width: 26px;
        height: 26px;
        margin-right: 7px;
        @media (max-width: 768px) {
          width: 18px;
          height: 18px;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }

      p {
        @include text($text-color, 20px, 500, normal, uppercase);
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }

    .price {
      @include flex(row, center, center, nowrap);
      margin-top: 7px;
      @media (max-width: 768px) {
        margin-top: 0px;
      }

      div {
        width: 24px;
        height: 24px;
        margin-right: 7px;
        @media (max-width: 768px) {
          width: 18px;
          height: 18px;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    p,
    span {
      @include text($text-color, 18px, 500, normal, none);
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
}
