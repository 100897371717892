@import "../../styles/varaibles.scss";
@import "../../styles/mixins.scss";

.footer {
  width: 100%;
  padding: 55px 0px 20px 0px;
  background-color: $secondaryColor;
  position: relative;
  margin-top: 50px;
  box-shadow: inset 0px 0px 100px 5px rgba(122, 101, 59, 0.9);
  @media (max-width: 768px) {
    margin-top: 25px;
    padding: 25px 0px 12px 0px;
  }

  .line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $text-color;
    margin-top: 31px;
    @media (max-width: 768px) {
      margin-top: 21px;
    }
  }
  .last-row {
    @include flex(column, center, center, nowrap);
    margin: 54px auto 0px;
    max-width: 700px;
    @media (max-width: 768px) {
      margin-top: 31px;
    }

    & span {
      @include text(#7c644a, 20px, 400, null, null);
      @media (max-width: 768px) {
        font-size: 14px;
      }
      cursor: pointer;
    }

    .description {
      @include flex(column, center, center, nowrap);
    }

    & div {
      @include text(#7c644a, 20px, 400, null, null);
      @include flex(row, center, null, wrap);
      column-gap: 34px;
      row-gap: 10px;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 14px;
        flex-direction: column;
        align-items: center;
        row-gap: 5px;
        margin-bottom: 5px;
      }

      & a {
        @include text(#7c644a, 20px, 400, null, null);
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
}
.footerError {
  margin-top: 0px;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
}
