@import "../../../styles/varaibles.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/extensions.scss";

.kitchen {
  width: 100%;
  background: $colorWhite;
  border: 1px solid rgba(88, 55, 17, 0.06);
  box-shadow: 0px 0px 17px 5px rgba(218, 203, 203, 0.68);
  padding: 20px 25px 47px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 15px;
  @media (max-width: 768px) {
    padding: 20px 22px;
    gap: 10px;
  }
  @media (max-width: 550px) {
    grid-row: 3/4;
  }
  @media (max-width: 330px) {
    padding: 15px;
  }

  h2 {
    margin-top: 0px;
    @extend %h2Title;
    margin-bottom: 8px;
    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
  }
}
